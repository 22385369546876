import '@/@fake-db/db'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import axios from 'axios'
import { VueFormBuilderPlugin } from 'v-form-builder'
import style from 'v-form-builder/public/css/bootstrap-4.3.1.min.css'
import Vue from 'vue'
import withUUID from 'vue-uuid'
import Pivot from '@webdatarocks/vue-webdatarocks'
import '@webdatarocks/webdatarocks/webdatarocks.min.css'
import { i18n } from '@/plugins/i18n'
import controls from '@/components/form-control/controls'
import PortalVue from 'vue2-portal'
import Vuelidate from 'vuelidate'
import App from './App.vue'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import 'v-form-builder/dist/v-form-builder.css'

Vue.use(Vuelidate)
validations: {}

Vue.use(PortalVue)

Vue.prototype.$http = axios

Vue.config.productionTip = false

Vue.use(VueFormBuilderPlugin, {
  disableControls: ['fileUploader', 'date', 'number', 'button'],
  globalInjection: true,
  styles: style,
  controls,
  validationErrorShowAlert: true,
  validationErrorAlertText: 'Please check the error messages and solve it.',
})

Vue.use(Pivot)

new Vue({
  router,
  store,
  i18n,
  vuetify,
  withUUID,
  render: h => h(App),
}).$mount('#app')
